<template>
  <div v-loading="loading">
    <nav-bar>
      <div class="edition-center">
        <div class="evaluate-container">
          <div class="title-bar">Solicitud de reembolso</div>
          <!-- <div class="evaluate-goods">
            <card-horizontal :moreVisible="true" :detailInfo="true" :goodsInfo="item"></card-horizontal>
          </div> -->
          <div class="input-container">
            <div class="select-bar">
              <div>
                <div class="title">
                  Por favor, seleccione el tipo de post-venta：
                </div>
                <el-select
                  v-model="activeType"
                  placeholder=""
                  :style="{ width: '540px' }"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <div class="title">razón de la solicitud de reembolso：</div>
                <el-select
                  v-model="active"
                  placeholder=""
                  :style="{ width: '540px' }"
                >
                  <el-option
                    v-for="item in reasonList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <!-- 售后数量 -->
            <div class="mtb-10px" v-if="activeType === 1">
              <div class="title">Cantidad postventa ：</div>
              <div
                v-for="(item, index) in afterSalesProducts"
                :key="index"
                class="after_sales_product flex items-center"
              >
                <div class="title mr-10px">{{ item.name }}</div>
                <el-input
                  v-model.number="item.number"
                  :style="{ width: '100px' }"
                />
              </div>
            </div>
            <div class="remark-bark">
              <div>Observaciones：</div>
              <div style="margin: 10px 0">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="Por favor ingrese observaciones del pedido"
                  v-model="message"
                  resize="none"
                ></el-input>
              </div>
            </div>
            <div style="display: flex">
              <div class="add">
                <upload-image
                  folder="head"
                  @listenPostImage="postImage($event)"
                  type="images"
                />
              </div>
              <template>
                <div
                  class="imgVisible col-delete"
                  v-for="(imgItem, imgIndex) in uploadImage"
                  :key="imgIndex"
                >
                  <img :src="imgItem | filterPicture" alt="" />
                  <i
                    @click="uploadImage.splice(imgIndex, 1)"
                    class="el-icon-error"
                  />
                </div>
              </template>
            </div>
          </div>
          <div class="submit">
            <el-button
              type="success"
              round
              :loading="btnLoading"
              style="width: 400px"
              @click="submitData"
            >
              Submission
            </el-button>
          </div>
        </div>
      </div>
    </nav-bar>
  </div>
</template>

<script>
// <upload-image folder="head" @listenPostImage="postImage($event, index, item)" type="images" />
import navBar from '@/components/navBar/index.vue'
import cardHorizontal from '@/components/product/card-horizontal.vue'
import UploadImage from '@/components/upload/image'
export default {
  components: {
    navBar,
    cardHorizontal,
    UploadImage
  },

  data() {
    return {
      activeNames: 1,
      active: '',
      activeType: 0,
      afterSalesQuantity: null,
      message: '',
      types: [
        { label: 'Sólo reembolso', value: 0 }, // 仅退款
        { label: 'Reembolso por falta de existencias', value: 1 }, //退货退款
        { label: 'Regreso', value: 2 } //换货
      ],
      typesList: ['REFUND', 'RETURNREFUND', 'BARTER'],
      reasonList: [],
      uploadImage: [],
      orderGoodsIdArry: [],
      btnLoading: false,
      loading: false,
      afterSalesProducts: []
    }
  },

  created() {
    console.log('this.$route :>> ', this.$route)
    // 如果当前页面被刷新了，则返回上一级以确保传参(orderGoodsId)的正确性
    if (!this.$route.params.orderGoodsId) {
      this.$router.back()
    }

    this.orderGoodsIdArry = this.$route.params.orderGoodsId

    this.getOrderDetail()
    this.getReasonList()
  },

  methods: {
    getOrderDetail() {
      this.$axios
        .post('/childOrder/detail/' + this.$route.params.id)
        .then((res) => {
          this.orderDetail = res.data.result
          console.log('订单详情', this.orderDetail)
          this.orderDetail.orderGoods.map((good) => {
            good.specs = JSON.parse(good.goodsSpecs)
            return good
          })
          // 过滤出售后的商品
          this.orderDetail.orderGoodsList.forEach((item) => {
            this.orderGoodsIdArry.forEach((goodsId) => {
              if (+item.id === +goodsId.id) {
                this.afterSalesProducts.push({
                  name: item.goodsName,
                  number: item.deliveryNumber
                })
              }
            })
          })
        })
    },

    getReasonList() {
      this.$axios.post('/orderAftersaleReason/list', {}).then((res) => {
        res.data.result.rows.map((item) => {
          this.reasonList.push({ label: item.content, value: item.id })
        })
      })
    },

    postImage(url) {
      this.uploadImage.push(url)
    },

    async submitData() {
      this.uploadList = JSON.stringify(this.uploadImage)
      const params = {
        orderId: this.orderDetail.orderId,
        orderGoodsIdList: this.orderGoodsIdArry.map((item) => {
          return item.id
        }),
        childOrderId: this.orderGoodsIdArry[0].childOrderId,
        reasonId: this.active,
        type: this.typesList[this.activeType],
        additionalImageUrl: this.uploadImage,
        additionalContent: this.message
      }
      this.loading = true
      try {
        await this.$axios.post('/childOrder/applyAfterSaleOrder', params)
        this.loading = false
        this.$router.push('/mine')
      } catch (error) {
        this.loading = false
        console.log('error :>> ', error)
      }
      console.log('售后 :>> ')
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.evaluate-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  // .evaluate-goods {
  //   padding: 10px 20px;
  //   margin-top: 20px;
  //   background: #F9FBF9;
  // }

  .input-container {
    margin: 40px 0;
    .title {
      margin-bottom: 10px;
    }
    .select-bar {
      display: flex;
      justify-content: space-between;
    }
    .remark-bark {
      margin-top: 20px;
    }
    .add {
      width: 120px;
      margin: 20px 20px 0 0;
      img {
        width: 100%;
      }
    }

    ::v-deep .el-textarea__inner {
      font-size: 16px;
    }
  }

  .submit {
    text-align: center;
  }
}

// 图片删除
.upload,
.imgVisible {
  display: inline-block;
  width: 120px;
  margin: 10px 20px 0 0;
  img {
    display: block;
    width: 100%;
    height: 120px;

    object-fit: cover;
  }
}

// 删除图标
.col-delete {
  position: relative;
  .el-icon-error {
    position: absolute;
    top: -7px;
    right: -7px;
    display: none;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
  &:hover {
    .el-icon-error {
      display: block;
    }
  }
}
.after_sales_product {
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 10px;
  }
}
</style>
